import APPDATA from './appData';
import Store from '../../store/store';

let ACTIONS;

const selectedMenuItem = (list, item) => {
  list.forEach((menu) => {
    let result = false;

    if (menu.items) {
      selectedMenuItem(menu.items, item);
    } else if (menu.type === item.type) {
      result = true;
    }

    // eslint-disable-next-line no-param-reassign
    menu.selected = result;
  });

  return list;
};

const updateRoute = (route, menuList) => {
  const newState = {
    params: route.params,
    opts: route.opts,
    name: route.route,
    menu: selectedMenuItem(menuList, { type: route.opts.menuItem }),
  };

  return newState;
};

const reducer = (action, state) => {
  switch (action.type) {
    case ACTIONS.APP.UPDATEROUTE:
      // eslint-disable-next-line no-param-reassign
      state = updateRoute(action.route, state.menu);
      break;
    case ACTIONS.APP.SELECTEDMENUITEM:
      // eslint-disable-next-line no-param-reassign
      state.menu = selectedMenuItem(state.menu, action.menuItem);
      break;
  }

  return state;
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');
  core.addStore('App', reducer, APPDATA);

  const createStore = () => new Store('App', reducer, APPDATA);

  return createStore();
}
