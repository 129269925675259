import ACTIONS from '../actions';

function Store(storeName, reducer, initialState) {
  if (typeof reducer !== 'function') {
    throw new Error('Expected the reducer to be a function.');
  }

  let currentState = initialState;
  const currentReducer = reducer;
  const listeners = [];
  let isDispatching = false;

  this.getState = () => currentState;

  this.subscribe = (listener) => {
    listeners.push(listener);
    let isSubscribe = true;

    // eslint-disable-next-line
    return function unsubscribe() {
      if (!isSubscribe) {
        return false;
      }

      isSubscribe = false;
      const index = listeners.indexOf(listener);
      listeners.splice(index, 1);
    };
  };

  this.dispatch = (action) => {
    if (action.type === undefined) {
      throw new Error('action type must be defined');
    }

    if (isDispatching) {
      throw new Error('not may dispatch action');
    }

    try {
      isDispatching = true;
      currentState = currentReducer(action, currentState);
    } finally {
      isDispatching = false;
    }

    listeners.slice().forEach(listener => listener(currentState));

    return action;
  };

  this.storeName = storeName;

  this.dispatch({ type: ACTIONS.STOREINIT });
}

export default Store;
